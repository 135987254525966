<template>
  <page :name="'Колличество активных пользователей'" :selectSource="false">
    <template v-slot:toolbar>
      <filter-component
          v-model:filter="filter"
          :liveReload="true"
          :selectButton="false"
          :selectLogsFilter="false"
          :selectViewFilter="false"
          @onLiveReload="liveReload"
      ></filter-component>
    </template>
    <tirscript3-loading :loading="isLoading"></tirscript3-loading>
    <chart-statistic v-show="flag" :chartModel="chartModel"></chart-statistic>

    <!--Сообщение об ошибке-->
    <div
        v-if="!error.isSuccess"
        class="row mt-2 justify-content-center"
    >
      {{ error.errorMessage }}
    </div>
  </page>
</template>
<script lang="ts">
import {Options, Vue, Watch} from "vue-property-decorator";
import Page from "@views/page/components/page.vue";
import ChartStatistic from "@/views/components/chart-statistic.vue";
import FilterModel from "@/views/page/components/filter-model";
import EndpointsResponseModel
  from "@/dataSource/api/AnalyticEndpoints/IServices/Models/Response/EndpointsResponseModel";
import ChartDataModel from "@/views/components/chartDataModel";
import ChartDatsetsModel from "@/views/components/chartDatasetsModel";
import ActiveUsersResponse from "@/dataSource/api/AnalyticUsers/IService/Models/Response/ActiveUsersResponse";
import getUTC from "@/function/getUTC"

@Options({
  name: "NumberOfOpenAndClosedSession",
  components: {
    Page,
    ChartStatistic,
  },
})
export default class NumberOfOpenAndClosedSession extends Vue {
  sourceLogId: number;
  filter: FilterModel = new FilterModel();
  endpointsResponse: Array<EndpointsResponseModel> = [];
  flag = false;
  isLoading = false

  isLiveReload = false
  timerId = 0

  error = {
    isSuccess: true,
    errorMessage: ""
  }

  datasets = new ChartDatsetsModel({
    label: "Кол-во активных пользователей",
    data: [],
    borderColor: "#fc2586",
    backgroundColor: "#fc2586",
    yAxisID: "y",
    pointStyle: "circle",
  });
  chartModel = new ChartDataModel({
    labels: [],
    datasets: [this.datasets],
  });

  @Watch("filter", {deep: true})
  update() {
    this.getData();
  }

  unmounted() {
    clearInterval(this.timerId)
  }

  liveReload(value) {
    this.isLiveReload = value
    if (value) {
      this.timerId = setInterval(() => {
        this.filter.DateTo = new FilterModel().DateTo
      }, 5000)
    } else {
      clearInterval(this.timerId)
    }
  }

  formatDate(stringDate: string | any): string {
    let index = stringDate.indexOf(" (");
    if (~index) {
      return stringDate.substr(0, index);
    }
  }

  async getData() {
    this.isLoading = true;
    let res: ActiveUsersResponse[];
    let request = {
      ProjectLogGroupId: this.sourceLogId,
      FromDate: this.filter.DateFrom,
      ToDate: this.filter.DateTo,
      Period: this.filter.interval,
    }
    let tmpFilter = getUTC(request)
    try {
      res = await this.$api.AnalyticUsersService.getActiveUsersAsync(
          tmpFilter
      );
    } catch (error) {
      this.error.isSuccess = false;
      this.error.errorMessage = error;
      this.isLoading = false;
    }
    this.flag = true;
    this.isLoading = false;
    this.chartModel.labels.splice(0, this.chartModel.labels.length);
    this.datasets.data.splice(0, this.datasets.data.length);
    res.forEach((elem) => {
      this.chartModel.labels.push(elem.TimePoint.toString());
      this.datasets.data.push(elem.UsersCount);
    });
  }
}
</script>

<style lang="less">
.date-wrapper {
  margin: 0 20px;
}

.toolbar {
  display: flex;
  justify-content: space-between;

  .tirscript-dropdown {
    flex: 0 1 45%;
  }
}

.table-icon {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>